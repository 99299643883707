import Bowser from "bowser";

export default {
  data() {
    return {
      browser: null,
      isBrave: false,
    };
  },
  computed: {
    isFirefox() {
      return this.browser.satisfies({
        firefox: ">=0",
      });
    },
    isChrome() {
      return this.browser.satisfies({
        chrome: ">=0",
        chromium: ">=0",
      });
    },
    isApple() {
      return this.browser.isOS("macos") || this.browser.isOS("ios");
    },
    browserHasIssuesWithThirdPartySession() {
      return this.isApple || this.isBrave;
    },
  },
  methods: {
    async checkBrave() {
      // https://github.com/bowser-js/bowser/issues/402

      if (typeof window?.navigator?.brave?.isBrave !== "function") {
        return;
      }

      this.isBrave = await Promise.resolve(window.navigator.brave.isBrave());

      // Ensure it's a boolean.
      //
      // The check above should already return a boolean, but that function is
      // way too vendor-specific (not a standard) so I wouldn't be surprised if
      // they change it at some point.
      this.isBrave = !!this.isBrave;
    },
  },
  async beforeMount() {
    //this.browser = Bowser.getParser("Mozilla/5.0 (Macintosh; Intel Mac OS X 14_7_1) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Safari/605.1.15");
    //this.browser = Bowser.getParser("Mozilla/5.0 (iPhone; CPU iPhone OS 17_7_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1");
    //this.browser = Bowser.getParser("Mozilla/5.0 (iPad; CPU OS 17_7_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1");

    this.browser = Bowser.getParser(window.navigator.userAgent);

    await this.checkBrave();
  },
}
