// Allow communication between browser windows.
//
// If a message is received, and has a type of "exampleType", then the method
// `this.onMessageExampleType` is called, passing the full message as argument
// (see the `messageEventListener` creation in `beforeMount).

export default {
  data() {
    return {
      messageEventListener: null,
    };
  },
  beforeMount() {
    this.messageEventListener = (event) => {
      if (!event.data || typeof event.data !== "object") {
        return;
      }
      if (event.data.source !== "hellobb") {
        return;
      }

      const message = event.data;

      const methodName = `onMessage${this.$utils.capitalize(message.type)}`
      if (typeof this[methodName] === "function") {
        this[methodName](message);
      }
    };

    window.addEventListener("message", this.messageEventListener);
  },
  unmounted() {
    if (this.messageEventListener) {
      window.removeEventListener("message", this.messageEventListener);
    }
  },
  methods: {
    sendMessageToWindow(w, type, payload = null) {
      w.postMessage({
        source: "hellobb",
        type,
        payload,
      }, "*");
    },
    sendMessageToParentWindow(type, payload = null) {
      console.log({ type, payload });
      return this.sendMessageToWindow(window.parent, type, payload);
    },
  },
}
